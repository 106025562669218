// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  white-space: nowrap;
  text-indent: 101%;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin hover {
  &:hover {
    @content;
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin hover-focus-active-visited {
  &:hover,
  &:focus,
  &:active,
  &:visited {
    @content;
  }
}
