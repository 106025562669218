$font-stack: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;

@import "custom";
@import "~bootstrap/scss/bootstrap";

@import "base/base";
@import "base/helpers";
@import "layout/header";
@import "layout/footer";
@import "pages/price";
@import "pages/contacts";
@import "pages/article";


.prod-item {
  text-align: center;
}
.products-block .prod-item {
  height: 290px;
  padding: 35px 0 0;
  position: relative;
}

p {
  font-size: 1.5em;
}
#date{
  color: rgb(14, 29, 231);
  text-align:right;
  font-size: 40px;
  position: fixed;
  z-index: 999;
  border: 2px;
  padding-top: 80px;
  font-weight: bold;
}
#par-1, #par-2 {
  position: relative;
  top: 1rem
}
#par-1 .thumbnail img,
#par-2 .thumbnail img {
  max-width: 100%;
  border: 3px solid black;
  position: relative;
}

#my-pics {
  padding: 10px;
  width: 40rem;
  margin: auto;
}
#bl{

  margin-top: 85px;
  padding: 10px;
  background-color: #a7acb0;
  z-index: 99;
}
#bl-1,#par {
  position: relative;
  top: 3.5rem;
  margin: 0 auto;
  padding: 10px;
  background-color: #a7acb0;
  z-index: 99;
}
#bl-2{
  max-width: 100%;
  background-color: #a7acb0;
}
#bl-3{
  position: relative;
  top: 3.5rem;
  margin: 0 auto;
  padding: 10px;
  background-color: #a7acb0;
}
#bl a{
  color: rgb(197, 191, 191);
}

li a.nav-link {
  font-weight: bold;
}
#rys {
  position: relative;
  top: 20rem;
  left: 10px;
  margin-bottom: 0;
  padding: 10px;
}

#tex,#tex1,#tex2{
  margin-top: 1em;
  z-index: -100;
}
.image{
  float:left;
  cursor:pointer;
  max-width:100%;
  z-index: 99;
}
/* Float four columns side by side */
.column {
  width: 25%;
  padding: 10px
}
#Date{
  margin-top: 110px;
}
.jumbotron {
  background-color: #96acc1;
  z-index: 999;
}
h2 {
  margin-top: 10px;
  text-align:center;
  background-color: #656b7b;
  font-size: 32px;
  color: #c9dcff;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.price {
  color: rgb(86, 243, 13);
  font-size: 22px;
}
.clone {
  position: absolute;
  top: 0;
  left: 0;
}
.clone > img {
  width: 100%;
  height: 100%;
}
/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(59, 47, 47, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #808c93;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(95, 91, 91, 0.2);
  border: 3px solid rgb(32, 45, 233);
  color: #0000d0;
}
/* Style the table */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}
.row {
  margin: 0 -5px;
  background-color: #656b7b
}
/* Style table headers and table data */
th, td {
  text-align: center;
  padding: 16px;
  color: rgb(255, 227, 0);;
}

th:first-child, td:first-child {
  text-align: center;
}

/* Zebra-striped table rows */
tr:nth-child(even) {
  background-color: #3b393927
}
h1 {
  font-family: 'Times New Roman', Times, serif;

}
p {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 14pt;
}
#foot{
  border: 1px solid white;
  text-align: center;
  background-color: #a4a8ac59;
}
#foot1{
  background-color: #a4a8ac59;
}
footer a{
  color: #d9bc1f;
}

#my-pics {
  padding: 10px;
  width: 100%;
  margin: auto;
}
.icon-about:after {
  content: " ";
  display: inline-block;
  height: 20px;
  width: 30px;
  background: url("./favicon.jpg") no-repeat;
}
.sticky-top{
  position: relative;
}

.btn {
  box-shadow: 5px 5px 0 RGB(121, 106, 95);
  margin-left: 4px
}
.container .content {
  position: absolute;
  top: 2rem;
  margin-right: 33em;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.2);
  color:yellow;
  width: 50%;
  padding: 10px 20px;
}
@media (max-width: 575.98px) {
  #tex h1,#tex p{
    margin-top: .5em;
    opacity: 1;
    font: 1.5em sans-serif;
  }
 

}



