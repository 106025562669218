body {
  height: 100%;
  min-height: 100%;
  position: relative;
  background: url("../img/spey2.jpg") repeat fixed 100% 100%;
  overflow-x: hidden;
}

ul,
ol {
  list-style-type: none;
}
